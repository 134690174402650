.videoCall {
	width: 100%;
	height: 100%;
}

.intro {
	width: 100%;
	height: 100%;
	max-width: 1000px;
	margin: 0 auto;
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.intro__cameraWrapper {
	width: 100%;
	height: 0;
	padding-top: 75%;
	position: relative;
	background-color: var(--color-background-secondary);
}

.intro__camera {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transform: rotateY(180deg);
}

.intro__panel {
	width: 50%;
	max-height: 500px;
	text-align: center;
}

.intro__title {
	font-size: 1.5em;
}

.intro__expire {
	color: red;
}

.intro__join {
	display: inline-block;
	padding: 0.5em 2em;
	border-radius: 1em;
	margin-top: 1em;
	background-color: var(--color-primary);
	color: var(--color-background);
	cursor: pointer;
	user-select: none;
}

.intro__controls {
	position: absolute;
	bottom: 10px;
	left: 0;
	right: 0;
	width: 100%;
	text-align: center;
}

.activeCall {
	width: 100%;
	height: 100%;
}

.userVideo {
	position: absolute;
	bottom: 2%;
	right: 2%;
	width: 25%;
	z-index: 100;
}

.userCamera__cameraWrapper {
	width: 100%;
	height: 0;
	padding-top: 75%;
}

.userCamera__camera {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transform: rotateY(180deg);
}

.grid {
	width: 100%;
	height: 100%;
	/*display: grid;*/
}

.grid__cell {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.grid__video {
	width: 100%;
	height: 100%;
}

.grid__video video {
	object-fit: contain;
}

.controls {
	position: absolute;
	bottom: 20px;
	left: 0;
	width: 100%;
	text-align: center;
}

.controls__button {
	display: inline-block;
	vertical-align: top;
	border: 1px solid #fff;
	font-size: 20px;
	height: 50px;
	width: 50px;
	line-height: 50px;
	border-radius: 50px;
	color: #fff;
	cursor: pointer;
	user-select: none;
	opacity: 0.9;
	margin: 0 10px;
}

.controls__button:hover {
	opacity: 1;
}

.controls__button.on {
	background-color: #fff;
	color: #333;
}

.controls__button.phone {
	border-color: var(--color-button-end);
	background-color: var(--color-button-end);
	color: white;
	font-size: 1.8em;
	transform: rotate(135deg);
}

.controls__button.phone:before {
	transform: translateY(-0.069444444444444em);
}

.ended {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 5%;
	text-align: center;
}

.ended__title {
	font-size: 1.5em;
}

.ended__redirect_link {
	margin-top: 10px;
	font-size: 14px;
	color: var(--color-text-light);
}

@media screen and (max-width: 600px) {
	.intro {
		flex-direction: column;
		padding: 10px;
	}

	.intro__title {
		margin-top: 10px;
	}

	.intro__panel {
		width: 90%;
	}
}
