.app:global(.dark) {
	/* https://www.colourlovers.com/palette/72905/iam_approachingnight */
	--color-primary: #cfff19;
	--color-secondary: #e0dbbd;
	--color-error: #eb1b3b;
	--color-background: #122125;
	--color-background-secondary: rgba(255, 255, 255, 0.3);
	--color-button-end: #eb1b3b;
	--color-text-light: rgba(255, 255, 255, 0.5);
}

.app {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: var(--color-background);
}

.notFound {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5%;
	font-size: 1.5em;
}

@media screen and (min-width: 960px) {
	.chatWrapper {
		padding: 30px 0;
	}
}
